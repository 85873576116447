import React from "react"
import { MDBContainer, MDBRow, MDBCol, MDBTypography ,MDBIcon} from "mdbreact"
import Layout from "../components/hoc/Layout"
import Check from "../resources/images/shape.svg"
import { Link } from "gatsby"
const ThankYou = () => {
  return (
    <Layout>
      <div className=" thanks">
        <div className="promo">
          <div style={{ height: "80px" }}></div>
        </div>
        <MDBContainer>
          <MDBRow>
            <MDBCol md="12">
              <div className="contnet">
                <MDBTypography tag="h2">Vielen Dank!</MDBTypography>
                <MDBTypography tag="p">
                Wir haben Ihre Nachricht erhalten und antworten bald.
                </MDBTypography>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="12">
              <div className="circle">
                <img src={Check} alt="vicreation Vielen Dank"/>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="12">
            <Link className="bottom" to="/">
                <MDBIcon
                  icon="chevron-right"
                  size="3x"
                  className="amber-text pr-3"
                />
                <MDBTypography tag="p">Ich habe mehr zu sagen.</MDBTypography>
              </Link>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </Layout>
  )
}

export default ThankYou